import * as React from 'react';
import { Seo } from '../components/Seo'
import { Layout } from '../components/Layout';
import ContactForm from '../components/Form/Contact';

const ContactTemplate = () => {
    return (
        <Layout>
            <Seo title="Contact" description="If you would like to get in contact with us please fill out the contact form." />
            <ContactForm/>
        </Layout>
    );
}

export default ContactTemplate;
